@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.footer {
  $block-name: &;

  padding-bottom: 40px;

  @include mq($to: xl) {
    padding-bottom: 30px;
  }

  @include mq($to: lg) {
    padding-bottom: 20px;
  }

  @include mq($to: md) {
    padding-bottom: 15px;
  }

  &__bottom {
    display: flex;

    @include mq($to: md) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__bottom-item {
    padding: 5px 16px;
  }

  &__bottom-item-link {
    display: inline-flex;
    width: auto;
    margin: -5px;
    padding: 5px;
    transition: opacity $transition-time;
    color: $white-100;
    font-family: $font-family;
    font-size: $font-size-2xs;
    font-weight: $font-weight-s;
    line-height: $line-height-l;
    opacity: $opacity-half;
    text-decoration: none;

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: $opacity-half;
    }
  }

  &__bottom-left {
    margin-right: 80px;
    margin-bottom: 15px;

    @include mq($to: md) {
      margin-right: 0;
      margin-left: 7px;
    }
  }

  &__bottom-right {
    @include unlist;
    @include reset-text-indents;

    display: flex;
    flex-wrap: wrap;
    margin: -5px -16px;
    margin-left: auto;

    @include mq($to: md) {
      margin-left: 0;
      justify-content: center;
    }
  }

  &__container {
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
  }

  &__copyright {
    color: $white-100;
    font-family: $font-family;
    font-size: $font-size-2xs;
    font-weight: $font-weight-s;
    line-height: $line-height-l;
    opacity: $opacity-half;
  }

  &__logo {
    display: inline-flex;
    margin: -5px;
    padding: 5px;
    margin-bottom: 22px;

    @include mq($to: md) {
      margin-bottom: 5px;

      img {
        width: 210px;
      }
    }

    &:active {
      opacity: $opacity;
    }
  }

  &__logo-img {
    display: inline-flex;
  }

  &__inner {
    background-color: $purple-500;
    padding: 50px 100px 30px;
    border-radius: $border-radius-m;

    @include mq($to: xxl) {
      padding: 50px 50px 30px;
    }

    @include mq($to: md) {
      padding: 50px 15px 15px;
    }
  }

  &__mail-link {
    display: inline-flex;
    margin: -5px;
    padding: 5px;
    text-decoration: none;
    color: $white-100;

    span {
      transition: opacity $transition-time;
    }

    &:hover {
      span {
        opacity: $opacity;
      }
    }

    &:active {
      span {
        opacity: 1;
      }
    }
  }

  &__mail-link-icon {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    margin-top: 0;
    margin-right: 12px;
  }

  &__mail-link-text {
    flex: 1 1 auto;
    font-family: $font-family;
    font-size: $font-size-xs;
    font-weight: $font-weight-m;
    line-height: $line-height-l;
  }

  &__office {
    display: flex;
    flex-wrap: nowrap;
  }

  &__office-description {
    display: block;
    color: $white-100;
    font-family: $font-family;
    font-size: $font-size-3xs;
    font-weight: $font-weight-s;
    line-height: $line-height-l;
    opacity: 0.8;
  }

  &__office-icon {
    color: $white-100;
    flex: 0 0 auto;
    margin-right: 10px;
    margin-top: -2px;

    &--office-1 {
    }

    &--office-2 {
      stroke: currentColor;
      stroke-width: 2;
    }
  }

  &__office-left {
  }

  &__office-right {
  }

  &__office-title {
    display: block;
    color: $white-100;
    font-family: $font-family;
    font-size: $font-size-2xs;
    font-weight: $font-weight-m;
    line-height: $line-height-l;
  }

  &__offices {
    @include reset-text-indents;
  }

  &__offices-list {
    @include unlist;

    display: flex;
    flex-wrap: wrap;
    gap: 23px 35px;
  }

  &__offices-title {
    margin-bottom: 18px;
  }

  &__title-link {
    margin: -5px;
    padding: 5px;
    color: $white-100;
    font-family: $font-family--headings;
    font-size: $font-size-2xs;
    font-weight: $font-weight-xl;
    line-height: $line-height-xs;
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity $transition-time;

    &--grey {
      opacity: $opacity-half;

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: $opacity-half;
      }
    }

    &--white {
      &:hover {
        opacity: $opacity;
      }

      &:active {
        opacity: 1;
      }
    }

    &--no-hover {
      opacity: 1 !important;
    }
  }

  &__top {
    display: flex;
    gap: 40px;
    margin-bottom: 42px;

    @include mq($to: xl) {
      display: block;
      margin-bottom: 50px;
    }
  }

  &__top-left {
    flex: 0 0 auto;
    width: 330px;

    @include mq($to: xl) {
      width: auto;
      margin-bottom: 50px;
    }

    @include mq($to: md) {
      width: auto;
      margin-bottom: 30px;
    }
  }

  &__top-left-grid {
    @include mq($to: xl) {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    @include mq($to: md) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__top-left-cell {
    font-size: 0;
    line-height: 0;

    @include mq($to: xl) {
      width: calc(33.3333% - 14px);

      &:first-child {
        align-self: center;
      }
    }

    @include mq($to: md) {
      width: auto;

      .socials {
        justify-content: center;

        &__item-link {
          padding: 10px;
        }

        &__item-icon {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  &__top-left-mail {
    margin-bottom: 27px;

    @include mq($to: md) {
      margin-bottom: 15px;
    }
  }

  &__top-left-title {
    margin-bottom: 18px;

    @include mq($to: md) {
      display: none;
    }
  }

  &__top-right {
    margin-left: auto;
  }
}
